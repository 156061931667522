import { Typography, Stack, Button, Grid } from '@mui/material';

import heroImage from '../assets/hero-image.png';
import trustedBrandsImage from '../assets/trusted-brands.png';

export const Section1 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ px: { sm: 0, lg: 8 } }}
      alignItems="flex-start"
    >
      <Grid item xs={12} md={6} sx={{ pb: { sm: '24px' }, pt: { xs: '48px' } }}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '48px',
            fontWeight: 'medium',
            lineHeight: '48px',
            width: { xs: '70%', lg: '60%' },
          }}
        >
          Pay and Earn Crypto for
        </Typography>
        <Typography
          variant="h2"
          color="primary.main"
          sx={{
            fontSize: '48px',
            fontWeight: 'regular',
            lineHeight: '48px',
            width: { lg: '60%' },
            pt: '16px',
          }}
        >
          Freelance Services
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{
            display: 'block',
            width: { sm: '90%', lg: '60%' },
            fontSize: 18,
            pt: '24px',
          }}
        >
          Cryptibiz is a platform that securely and efficiently connects sellers
          and clients for all kinds of jobs all around the world. Wich in return
          will be paid for in crypto currency.
        </Typography>
        <Stack direction="row" mt="40px" gap={2}>
          <Button variant="contained" sx={{ px: '20px', py: '12px' }}>
            Get Started
          </Button>
          <Button
            variant="outlined"
            color="custom"
            sx={{ px: '20px', py: '12px', color: 'primary.main' }}
          >
            Find Talent
          </Button>
        </Stack>
        <Stack mt="48px" gap={2}>
          <Typography compnent="h2" sx={{ fontSize: 14, fontWeight: 'medium' }}>
            Trusted By
          </Typography>
          <img
            src={trustedBrandsImage}
            style={{
              width: '200px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', lg: 'flex-end' },
          pt: { xs: '40px' },
        }}
      >
        <img
          src={heroImage}
          style={{ width: '500px', maxWidth: '100%', objectFit: 'contain' }}
          alt=""
        />
      </Grid>
    </Grid>
  );
};
