import { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Stack,
  Avatar,
  IconButton,
  Divider,
  Rating,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Container,
  Paper,
} from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import {
  AccessTime,
  ArrowForward,
  Cached,
  Check,
  CurrencyBitcoin,
  Facebook,
  Inventory2Outlined,
  ShoppingCartOutlined,
  Star,
  Telegram,
  Twitter,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Parser } from 'html-to-react';
import { getDoc, doc } from 'firebase/firestore';
import {
  TwitterShareButton,
  TelegramShareButton,
  FacebookShareButton,
} from 'react-share';
import { db } from '../../config/firebase';

function getPrecision(number) {
  var integerPart = Math.floor(number);
  var precision = number - integerPart;

  return precision;
}

function TabContainer({ pk, children, packageName }) {
  const [open, setOpen] = useState(false);
  const handleBuyOpen = () => {
    setOpen(true);
  };

  const handleBuyClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ padding: `${8 * 3}px` }}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleBuyClose}
        aria-labelledby="buy-dialog-title"
        aria-describedby="buy-dialog-description"
      >
        <DialogTitle id="buy-dialog-title" fontSize="24px" fontWeight="600">
          Buy this Task
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="body1" fontWeight="500" display="inline">
                {!pk.isCompletePackage ? 'Complete Package ' : pk.title + ' '}
              </Typography>
              <Typography variant="body2" display="inline">
                {pk.desc}
              </Typography>
            </Box>
            <Paper elevation={0} sx={{ background: '#f5f5f5', p: '24px' }}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h2" fontSize="24px" fontWeight="600">
                    {pk.price}$
                  </Typography>
                  <Typography variant="body2" fontSize="14px">
                    Single Order
                  </Typography>
                </Box>
                <Divider />
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.7} alignItems="center">
                    <Inventory2Outlined
                      sx={{ width: '20px', height: '20px' }}
                    />{' '}
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {packageName} package
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={0.7} alignItems="center">
                    <AccessTime sx={{ width: '20px', height: '20px' }} />{' '}
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {pk.deliveryTime} Days delivery
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={0.7} alignItems="center">
                    <Cached sx={{ width: '20px', height: '20px' }} />{' '}
                    <Typography
                      variant="body1"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {pk.revisions} revision
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBuyClose}>Cancel</Button>
          <Button variant="contained" onClick={handleBuyClose} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      {children}{' '}
      <Button
        variant="contained"
        sx={{ mt: '16px', width: '100%' }}
        endIcon={<ArrowForward />}
        onClick={handleBuyOpen}
      >
        Buy this Task
      </Button>
    </Box>
  );
}

function TabContainerNew({ pk, packageName }) {
  return (
    <TabContainer pk={pk} packageName={packageName}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: '24px' }}>
        {pk && (
          <Typography variant="h3" fontSize="20px" fontWeight="600">
            {!pk.isCompletePackage ? 'Complete Package' : pk.title}
          </Typography>
        )}
        {pk.price && (
          <Typography variant="h3" fontSize="20px" fontWeight="400">
            ${pk.price}
          </Typography>
        )}
      </Stack>
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight="400"
        sx={{
          height: '63px',
          lineHeight: '140%',
          wordBreak: 'break-word',
          mb: '24px',
        }}
      >
        {pk.desc}
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ mb: '8px' }}
      >
        {pk.payment && (
          <Stack direction="row" spacing={0.7} alignItems="center">
            <CurrencyBitcoin sx={{ width: '20px', height: '20px' }} />{' '}
            <Typography variant="body1" fontSize="14px" fontWeight="500">
              {pk.payment}
            </Typography>
          </Stack>
        )}

        {pk.purchases && (
          <Stack direction="row" spacing={0.7} alignItems="center">
            <ShoppingCartOutlined sx={{ width: '20px', height: '20px' }} />{' '}
            <Typography variant="body1" fontSize="14px" fontWeight="500">
              {pk.purchases + ' purchases'}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ mb: '16px' }}
      >
        {pk.deliveryTime && (
          <Stack direction="row" spacing={0.7} alignItems="center">
            <AccessTime sx={{ width: '20px', height: '20px' }} />{' '}
            <Typography variant="body1" fontSize="14px" fontWeight="500">
              {pk.deliveryTime} Days Delivery
            </Typography>
          </Stack>
        )}
        {pk.revisions && (
          <Stack direction="row" spacing={0.7} alignItems="center">
            <Cached sx={{ width: '20px', height: '20px' }} />{' '}
            <Typography variant="body1" fontSize="14px" fontWeight="500">
              {pk.revisions} Revisions
            </Typography>
          </Stack>
        )}
      </Stack>
      <List disablePadding>
        {pk.features &&
          pk.features.map((feature, k) => (
            <ListItemNew
              key={k}
              icon={Check}
              text={feature.title}
              isDisabled={!feature.isChecked}
            />
          ))}
      </List>
    </TabContainer>
  );
}

const ListItemNew = ({ text, isDisabled }) => {
  return (
    <ListItem disableGutters disablePadding>
      <ListItemIcon sx={{ minWidth: '32px' }}>
        <Check
          sx={{
            stroke: isDisabled ? '#dadbdd' : '#1dbf73',
            strokeWidth: '2',
            width: '20px',
            height: '20px',
          }}
        />
      </ListItemIcon>
      <ListItemText primary={text}></ListItemText>
    </ListItem>
  );
};

const CustomerRating = () => (
  <Stack
    direction="row"
    spacing={1.5}
    sx={{ my: '16px' }}
    alignItems="flex-start"
  >
    <IconButton sx={{ p: 0 }}>
      <Avatar
        alt="Anass Soukrat"
        src="https://thispersondoesnotexist.com/"
        sx={{ width: '32px', height: '32px' }}
      />
    </IconButton>
    <Stack spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h4" fontSize="18px" fontWeight="600">
          Nicolas.q
        </Typography>
        <Star
          sx={{
            color: 'primary.stars',
            width: '20px',
            height: '20px',
          }}
        />
        <Typography
          variant="h3"
          fontSize="16px"
          fontWeight="600"
          color="primary.stars"
        >
          5.0
        </Typography>
      </Stack>
      <Typography variant="h3" fontSize="16px" fontWeight="400" color="#9B9B9B">
        Morocco
      </Typography>
      <Typography variant="body1" sx={{ mt: '12px !important' }}>
        My best experience on Fiverr so far. ^^ Anass is very sedulous, super
        easy to talk to and work with, and happily does changes. Also comes up
        with nice ideas !
      </Typography>
      <Typography
        variant="body2"
        sx={{ mt: '12px !important' }}
        color="#9B9B9B"
      >
        Published 2 months ago
      </Typography>
    </Stack>
  </Stack>
);

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(() => ({
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: '1rem',
          color: 'primary.main',
          fontWeight: 'bold',
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: '0',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function splitByComma(str) {
  var substrings = str.split(',');

  var trimmedSubstrings = substrings.map(function (substring) {
    return substring.trim();
  });

  return trimmedSubstrings;
}

const initialPackages = [
  {
    title: '',
    price: '',
    desc: '',
    payment: '',
    purchases: '',
    deliveryTime: '',
    revisions: '',
    features: '',
    isCompletePackage: '',
  },
  {
    title: '',
    price: '',
    desc: '',
    payment: '',
    purchases: '',
    deliveryTime: '',
    revisions: '',
    features: '',
    isCompletePackage: '',
  },
  {
    title: '',
    price: '',
    desc: '',
    payment: '',
    purchases: '',
    deliveryTime: '',
    revisions: '',
    features: '',
    isCompletePackage: '',
  },
];

function SingleTask() {
  const { taskId } = useParams();
  const [task, setTask] = useState('');
  const [taskTags, setTaskTags] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [maxVisibleChips, setMaxVisibleChips] = useState(0);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [packages, setPackages] = useState(initialPackages);

  function handleBreadcrumb(event) {
    event.preventDefault();
    console.info(event.target.href);
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setOpen(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabClick = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setMaxVisibleChips(3); // Set 3 visible chips for mobile
      } else if (screenWidth < 1024) {
        setMaxVisibleChips(4); // Set 5 visible chips for iPad or tablets
      } else {
        setMaxVisibleChips(taskTags.length); // Set 4 visible chips for other screen sizes
      }
    };

    if (!task) {
      const fetchTask = async () => {
        try {
          const docSnap = await getDoc(doc(db, 'tasks', taskId));
          const data = docSnap.data() && docSnap.data().data;

          if (data) {
            setTask(data);
            setTaskTags(splitByComma(data.tags));
            setPackages([
              {
                title: data.basicTitle,
                price: data.basicPrice,
                desc: data.basicDescription,
                payment: data.acceptedPayments,
                purchases: data.basicPurchases,
                deliveryTime: data.basicDelivery,
                revisions: data.basicRevision,
                features: data.basicFeatures,
                isCompletePackage: data.isCompletePackage,
              },
              {
                title: data.standardTitle,
                price: data.standardPrice,
                desc: data.standardDescription,
                payment: data.acceptedPayments,
                purchases: data.standardPurchases,
                deliveryTime: data.standardDelivery,
                revisions: data.standardRevision,
                features: data.standardFeatures,
                isCompletePackage: data.isCompletePackage,
              },
              {
                title: data.premiumTitle,
                price: data.premiumPrice,
                desc: data.premiumDescription,
                payment: data.acceptedPayments,
                purchases: data.premiumPurchases,
                deliveryTime: data.premiumDelivery,
                revisions: data.premiumRevision,
                features: data.premiumFeatures,
                isCompletePackage: data.isCompletePackage,
              },
            ]);
          }
        } catch (e) {
          console.error('Error retrieving tasks: ', e);
        }
      };
      fetchTask();
    }

    handleResize(); // Call the function initially
    window.addEventListener('resize', handleResize); // Add event listener for resize
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, [taskTags.length, taskId, task]);
  return (
    <Container maxWidth="xl">
      {task && (
        <Grid container sx={{ mt: '24px' }} justifyContent="space-between">
          <Grid item lg sx={{ mr: '56px' }}>
            <Stack>
              {task && (
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/tasks"
                    sx={{ color: 'primary.main' }}
                  >
                    Tasks
                  </Link>
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    onClick={handleBreadcrumb}
                    sx={{ color: 'primary.main' }}
                  >
                    {task.category}
                  </Link>
                  ,
                  <Typography key="2" color="text.primary">
                    {task.subCategory}
                  </Typography>
                  ,
                </Breadcrumbs>
              )}

              {/* Task Title */}
              {task && (
                <Typography
                  variant="h3"
                  fontSize="28px"
                  fontWeight="600"
                  sx={{ mt: '16px' }}
                >
                  I will {task.title}
                </Typography>
              )}

              {/* User details 1 */}
              {task && (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ mt: '16px' }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <Avatar
                      alt={task.userName}
                      src={task.userImage}
                      sx={{ width: '32px', height: '32px' }}
                    />
                  </IconButton>
                  <Typography variant="h4" fontSize="18px" fontWeight="600">
                    {task.userName}
                  </Typography>
                  <Typography variant="h4" fontSize="14px">
                    {task.levelStatus}
                  </Typography>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Rating
                    name="read-only"
                    value={parseFloat(task.rating)}
                    precision={
                      getPrecision(parseFloat(task.rating)) === 0
                        ? 0.1
                        : getPrecision(parseFloat(task.rating))
                    }
                    size="small"
                    readOnly
                    sx={{ color: 'primary.stars' }}
                  />
                  <Typography variant="body2">({task.ratingCount})</Typography>
                </Stack>
              )}

              {/* Task Details */}
              {/* Task Image */}
              <Box
                sx={{
                  marginTop: '16px',
                  overflow: 'hidden',
                  width: { xs: '100%', md: '80%' },
                  height: {
                    xs: '300px',
                    md: '400px',
                    lg: '500px',
                  },
                  display: 'inline-flex',
                  borderRadius: '4px',
                  backgroundColor: '#f5f5f5',
                  '&:hover img': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <img
                  src={task && task.imgUrl}
                  alt=""
                  onClick={(e) => handleImage()}
                  style={{
                    transition: 'transform .4s ease-in-out',
                    cursor: 'pointer',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
                <Dialog
                  maxWidth="xl"
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      display: 'inline-flex',
                    }}
                  >
                    <img
                      src={task && task.imgUrl}
                      alt=""
                      style={{
                        transition: 'transform .5s ease',
                        cursor: 'pointer',
                        width: '100%',
                        height: '500px',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                </Dialog>
              </Box>

              {/* Task Description */}
              <Typography
                variant="h3"
                fontWeight="medium"
                fontSize="20px"
                sx={{ mt: '40px' }}
              >
                About this task
              </Typography>
              <Box sx={{ mt: '16px', '& p': { margin: 0 } }}>
                {task && Parser().parse(task.summary)}
              </Box>

              <Divider sx={{ my: '40px' }} />

              {/* FAQ */}
              <Stack spacing={2}>
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'medium',
                  }}
                >
                  FAQ
                </Typography>
                <Box>
                  <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography>
                        Aliquet lectus urna viverra in odio?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Suspendisse malesuada lacus ex, sit
                        amet blandit leo lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                  >
                    <AccordionSummary
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    >
                      <Typography>
                        Orci commodo, viverra orci mollis ut euismod?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Suspendisse malesuada lacus ex, sit
                        amet blandit leo lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                  >
                    <AccordionSummary
                      aria-controls="panel3d-content"
                      id="panel3d-header"
                    >
                      <Typography>
                        Sagittis vitae facilisi rutrum amet mauris quisque vel
                        convallis?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Suspendisse malesuada lacus ex, sit
                        amet blandit leo lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                  >
                    <AccordionSummary
                      aria-controls="panel4d-content"
                      id="panel4d-header"
                    >
                      <Typography>
                        In id dolor quis nunc, urna hendrerit pharetra?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Suspendisse malesuada lacus ex, sit
                        amet blandit leo lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Stack>

              {/* Rating */}
              <Stack spacing={2} sx={{ mt: '56px', mb: '16px' }}>
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'medium',
                  }}
                >
                  Rating
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Typography variant="body1" fontWeight="medium">
                    {task && task.ratingCount} reviews for this Task
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {task && (
                      <>
                        <Rating
                          name="read-only"
                          value={
                            !isNaN(parseFloat(task.rating))
                              ? parseFloat(task.rating)
                              : 0
                          }
                          precision={
                            getPrecision(parseFloat(task.rating)) === 0
                              ? 0.1
                              : getPrecision(parseFloat(task.rating))
                          }
                          size="small"
                          readOnly
                          sx={{ color: 'primary.stars' }}
                        />
                        <Typography
                          variant="h3"
                          fontSize="16px"
                          fontWeight="600"
                          color="primary.stars"
                          lineHeight={0}
                        >
                          {!isNaN(parseFloat(task.rating))
                            ? parseFloat(task.rating).toString()
                            : ''}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              {/* Ratings */}
              <CustomerRating />
              <Divider />
              <CustomerRating />

              {/* Rating */}
              <Stack spacing={2} sx={{ mt: '56px', mb: '16px' }}>
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'medium',
                  }}
                >
                  Related Tags
                </Typography>
                <Box>
                  {task &&
                    taskTags.slice(0, maxVisibleChips).map((chip, index) => (
                      <Chip
                        key={index}
                        label={chip}
                        sx={{
                          fontSize: { xs: '12px', md: '14px' },
                          color: 'text.primary',
                          borderColor: '#dadbdd',
                          marginRight: '8px',
                          marginBottom: { xs: '8px', md: '0px' },
                        }}
                        variant="outlined"
                      />
                    ))}
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            lg={3.5}
            md
            sm
            xs
            sx={{
              mt: { xs: '24px', lg: '48px' },
              position: 'sticky',
              top: '120px',
            }}
          >
            <Card variant="outlined">
              <CardContent
                sx={{
                  p: 0,
                  pb: 0,
                  '&:last-child': {
                    pb: 0,
                  },
                }}
              >
                {task &&
                  (task.isCompletePackage ? (
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleTabClick}
                        variant="fullWidth"
                        sx={{
                          bgcolor: '#fafafa',
                          borderBottom: 1,
                          borderColor: 'divider',
                        }}
                      >
                        <Tab label="Basic" {...a11yProps(0)} />
                        <Tab label="Standard" {...a11yProps(1)} />
                        <Tab label="Premium" {...a11yProps(2)} />
                      </Tabs>
                      {value === 0 && (
                        <TabContainerNew pk={packages[0]} packageName="Basic" />
                      )}
                      {value === 1 && (
                        <TabContainerNew
                          pk={packages[1]}
                          packageName="Standard"
                        />
                      )}
                      {value === 2 && (
                        <TabContainerNew
                          pk={packages[2]}
                          packageName="Premium"
                        />
                      )}
                    </Box>
                  ) : (
                    <TabContainerNew pk={packages[0]} packageName="Complete" />
                  ))}
              </CardContent>
            </Card>
            <Stack
              direction="row"
              sx={{ p: '12px' }}
              spacing={2}
              alignItems="center"
            >
              <Typography variant="body1" fontSize="14px">
                Share this Task
              </Typography>
              <Stack direction="row" spacing={1} sx={{ color: 'primary.main' }}>
                <FacebookShareButton
                  style={{ lineHeight: 0 }}
                  url={'https://cryptibiz.com/tasks/' + task.id}
                  hashtags={taskTags}
                  quote={'I will' + task.title}
                >
                  <Facebook />
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ lineHeight: 0 }}
                  url={'https://cryptibiz.com/tasks/' + task.id}
                  hashtags={taskTags}
                  title={'I will' + task.title}
                >
                  <Twitter />
                </TwitterShareButton>
                <TelegramShareButton
                  style={{ lineHeight: 0 }}
                  url={'https://cryptibiz.com/tasks/' + task.id}
                  title={'I will' + task.title}
                >
                  <Telegram />
                </TelegramShareButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default SingleTask;
