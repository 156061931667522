import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { scroller } from 'react-scroll';

const helpList = [
  'Add more details about your offering and why clients should work with you.',
  'Show potential clients the steps you take to complete your task.',
  'Address common client questions to save the back and forth.',
];

const modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

export function Step5({
  handleNext,
  handleBack,
  handleChange,
  values: { taskSummary },
  formErrors,
}) {
  const isValid = taskSummary.length > 0 && !formErrors.taskSummary;

  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  function handleBackAndScroll(e) {
    handleBack(e);
    handleScrollUp();
  }

  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Task summary
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px', width: '100%' }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                variant="h3"
                sx={{ fontSize: '22px', fontWeight: '500' }}
              >
                Task description
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', color: 'text.secondary' }}
              >
                Briefly explain what sets you and your task apart.
              </Typography>
              <Box sx={{ mt: '16px !important', width: '100%' }}>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  onChange={handleChange}
                  style={{ width: '700px' }}
                  error={!!formErrors.taskRequirements}
                  value={taskSummary}
                  aria-label="NA"
                />
                <Typography
                  variant="body2"
                  sx={{ mt: 1, color: formErrors.taskSummary && 'red' }}
                >
                  {formErrors.taskSummary || '1200 characters max'}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{ px: '32px', py: '24px', maxWidth: '310px' }}
          >
            <CardHeader
              title="Task details"
              sx={{
                p: 0,
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: '16px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {helpList.map((label, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemText
                      sx={{ color: 'primary.main', fontSize: '14px' }}
                    >
                      {label}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="inherit" onClick={handleBackAndScroll} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={isValid ? handleNextAndScroll : null}
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Box>
    </Stack>
  );
}
