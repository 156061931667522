import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography, styled } from '@mui/material';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import CryptibizLogo from '../../assets/cryptibiz.png';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const pages = [
  { tasks: 'Tasks' },
  { jobs: 'Jobs' },
  { 'fulltime-jobs': 'Fulltime Jobs' },
  { 'how-it-works': 'How it works' },
  { blog: 'Blog' },
];

export default function MainHeader() {
  const theme = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AppBar
      position="static"
      sx={{ boxShadow: 0, backgroundColor: 'transparent', mt: 2 }}
    >
      <Container maxWidth="xl">
        <StyledToolbar>
          {/* Mobile SideBar & Button */}
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'flex', md: 'none' } }}
            onClick={handleSidebarToggle}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={'left'}
            open={isSidebarOpen}
            onClose={handleSidebarToggle}
          >
            <Box width={'300px'}>
              <List>
                {pages.map((text, index) => (
                  <ListItem key={Object.keys(text)[0]} disablePadding>
                    <ListItemButton href={Object.keys(text)[0]}>
                      {Object.values(text)[0]}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary={'Register'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>

          <Stack direction="row" gap={8} alignItems={'center'}>
            <Link href="/">
              <img
                src={CryptibizLogo}
                width={110}
                alt=""
                style={{ display: 'block' }}
              />
            </Link>
            <Stack
              direction="row"
              gap={4}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {pages.map((page) => (
                <Typography
                  href={Object.keys(page)[0]}
                  key={Object.keys(page)[0]}
                  component="a"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: '600',
                    textDecoration: 'none',
                  }}
                >
                  {Object.values(page)[0]}
                </Typography>
              ))}
            </Stack>
          </Stack>

          <Stack direction="row" alignItems={'center'} gap={2}>
            <Typography
              href="/"
              component="a"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: '600',
                textDecoration: 'none',
              }}
            >
              Sign in
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: 32,
                px: 3,
                fontWeight: '600',
                display: { xs: 'none', sm: 'flex' },
              }}
            >
              Join
            </Button>
          </Stack>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
