import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { scroller } from 'react-scroll';

const categories = ['Graphics & Design', 'Programming & Tech'];

const subcategories = [
  ['Logo Design', 'Game Art'],
  ['Website Development', 'Game Development'],
];

export function Step1({
  handleNext,
  handleChange,
  handleSubcategoryChange,
  handleCategoryChange,
  values: { taskTitle, taskTags, category, subcategory },
  formErrors,
}) {
  const isValid =
    taskTitle.length > 0 &&
    !formErrors.taskTitle &&
    taskTags.length > 0 &&
    !formErrors.taskTags &&
    !formErrors.category &&
    !formErrors.subcategory &&
    category &&
    subcategory;
  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Project overview
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px' }}>
          <Stack spacing={4}>
            {/* Package Title */}
            <Stack direction="column" spacing={1}>
              <Typography variant="body1" fontWeight="500" fontSize="20px">
                Title
              </Typography>
              <Typography variant="body1" fontSize="16px">
                Tell the client what you will deliver and how it benefits them.
              </Typography>
              <TextField
                name="taskTitle"
                helperText="80 characters max (min. 5 words)"
                value={taskTitle || ''}
                error={!!formErrors.taskTitle}
                onChange={handleChange}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">I will</InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #E4E5E7',
                  },
                }}
              />
            </Stack>

            <Divider />

            {/* Category */}
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <Typography variant="body1" fontWeight="500" fontSize="20px">
                Category
              </Typography>
              <Typography variant="body1" fontSize="16px">
                Select a category so it's easy for clients to find your project.
              </Typography>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ width: '250px' }}>
                  <Select
                    id="category"
                    value={category || ''}
                    displayEmpty
                    required
                    error={!!formErrors.category}
                    onChange={handleCategoryChange}
                    size="small"
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      Select a Category
                    </MenuItem>
                    {categories.map((value, key) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formErrors.category}</FormHelperText>
                </FormControl>

                <FormControl sx={{ width: '250px' }}>
                  <Select
                    id="subcategory"
                    value={subcategory || ''}
                    displayEmpty
                    required
                    error={!!formErrors.subcategory}
                    onChange={handleSubcategoryChange}
                    size="small"
                    disabled={!category}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      Select a Subcategory
                    </MenuItem>
                    {category !== '' &&
                      subcategories[categories.indexOf(category)].map(
                        (value, key) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  <FormHelperText>{formErrors.subcategory}</FormHelperText>
                </FormControl>
              </Stack>
            </Stack>

            <Divider />

            {/* Search tags */}
            <Stack direction="column" spacing={2} alignItems="flex-start">
              <Typography variant="body1" fontWeight="500" fontSize="20px">
                Search tags
              </Typography>
              <Typography
                variant="body1"
                fontSize="14px"
                sx={{ width: '500px' }}
              >
                Tag your Task with buzz words that are relevant to the services
                you offer. Use all 5 tags to get found.
              </Typography>
              <TextField
                name="taskTags"
                placeholder="Start typing to view & select options."
                helperText={formErrors.taskTags || 'e.g. tag1, tag2, tag3...'}
                fullWidth
                value={taskTags || ''}
                error={!!formErrors.taskTags}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 80 }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #E4E5E7',
                  },
                }}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{ px: '32px', py: '24px', maxWidth: '310px' }}
          >
            <CardHeader
              title="Need help getting started?"
              sx={{
                p: 0,
                mb: '12px',
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <Typography variant="h4" fontSize="16px">
                Review these resources to learn
              </Typography>
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: '16px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                <ListItem disablePadding>
                  <ListItemText
                    sx={{ color: 'primary.main', fontSize: '14px' }}
                  >
                    Step-by-step
                  </ListItemText>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    sx={{ color: 'primary.main', fontSize: '14px' }}
                  >
                    Tips and tricks
                  </ListItemText>
                </ListItem>
              </List>
              <Typography variant="h4" fontSize="16px">
                You can always come back and change your project later.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          onClick={isValid ? handleNextAndScroll : null}
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Box>
    </Stack>
  );
}
