import { Typography, Stack, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';

import Gig from '../components/Gig';

export const Section5 = () => {
  const handleSellerChip = () => {
    console.log('handleSellerChip');
  };
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'column' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '180px' }}
      justifyContent="center"
    >
      <Grid item>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: 'medium',
            lineHeight: '48px',
            pt: '36px',
            textAlign: 'center',
          }}
        >
          Sellers Catalog
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{ fontSize: '22px', pt: '16px', textAlign: 'center' }}
        >
          We have hundreds of featured sellers
        </Typography>
      </Grid>
      <Grid item sx={{ mt: '32px' }}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Chip
            label="Featured"
            onClick={handleSellerChip}
            sx={{
              background: '#ECECFE',
              fontWeight: 'medium',
              fontSize: '16px',
            }}
          />
          <Chip
            label="Popular"
            onClick={handleSellerChip}
            sx={{
              background: 'transparent',
              fontSize: '16px',
              color: 'text.secondary',
            }}
          />
          <Chip
            label="Saved"
            onClick={handleSellerChip}
            sx={{
              background: 'transparent',
              fontSize: '16px',
              color: 'text.secondary',
            }}
          />
          <Chip
            label="Pro services"
            onClick={handleSellerChip}
            sx={{
              background: 'transparent',
              fontSize: '16px',
              color: 'text.secondary',
            }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: '24px' }}>
        <Grid container justifyContent="center" spacing={2.5}>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
